<template>
  <div>

    <div v-if="isDataLoading"
      style="position: relative; top: 120px;">
      <spinner />
    </div>

    <div class="field">
      <div class="columns is-gapless">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-body">
                <div class="field has-addons">
                  <div class="control has-icons-left has-icons-right">
                    <input class="input"
                      v-model="filter.query"
                      type="text"
                      placeholder="Start typing to filter"
                      @input="search">
                    <span class="icon is-left">
                      <i class="mdi mdi-magnify mdi-18px" />
                    </span>
                  </div>
                  <div class="control">
                    <a class="button is-primary is-outlined tooltip"
                      :class="[ filter.butterflyCode ? 'is-warning' : 'is-primary' ]"
                      :data-tooltip="filter.butterflyCode ? 'Active Butterfly search' : 'Butterfly search'"
                      @click="butterflySearch()">
                      <span class="icon">
                        <i class="fqi fqi-butterfly fqi-20px" />
                      </span>
                    </a>
                  </div>
                  <div class="control">
                    <a class="button is-primary is-outlined tooltip"
                      data-tooltip="Reset search"
                      @click="reset()">
                      <span class="icon">
                        <i class="mdi mdi-close mdi-24px" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-narrow"
          v-if="showScrollToAdd"
          style="display: flex; align-items: center">
          <div class="field-body is-pulled-right">
            <div class="field is-narrow">
              <input class="is-checkradio"
                id="labourScroll"
                type="checkbox"
                :checked="$userInfo.scrollItemPart"
                @change="$setScrollItemPart"
                name="labourScroll">
              <label for="labourScroll">Scroll on add</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!------------------------ EXPAND / MINIMISE  ------------------------------>
    <div class="is-flex is-justify-content-space-between is-align-items-center mr-4">
      <div />
      <div class="is-flex">
        <!-- <div class="button is-text"
          @click="handleMinimiseAll">Minimise All</div> -->
        <div class="button is-text"
          @click="handleExpandAll">
          {{ isExpandAll ? 'Minimise All' : 'Expand All' }}
        </div>
      </div>
    </div>

    <!-------------  Container for all the butterfly items  ---------->
    <div class="table-container-avaialble-butterfly-items">

      <!-- {{ availableItems.length }} -->

      <div v-for="(itemCategory, index) in sortedData"
        :key="itemCategory.butterflyCode + index">

        <div class="butterfly-list-labour-item-category m-1 p-2 is-clickable"
          @click="handleToggleButterflyCode(itemCategory.butterflyCode)">
          <div>
            {{ itemCategory.butterflyDesc }}
          </div>

          <div class="has-text-centered">
            <span class="is-underlined"
              v-tippy="{ content: 'Add all items in this category' }"
              @click="e => handleAddAllItems(itemCategory.items, e)"
              v-if="isButterflyCodeSelected(itemCategory.butterflyCode)">
              Add
            </span>
          </div>

          <span class="material-symbols-rounded"
            style="user-select: none;">
            {{ isButterflyCodeSelected(itemCategory.butterflyCode)
              ? 'keyboard_arrow_down'
              : 'keyboard_arrow_right'
            }}
          </span>

        </div>
        <div v-if="isButterflyCodeSelected(itemCategory.butterflyCode)">
          <div v-for="(partItem, laboutItemindex) in itemCategory.items"
            :key="partItem.itemNo + laboutItemindex"
            class="butterfly-list-labour-item m-1 p-1">
            <div>{{ partItem.description }}</div>

            <div class="has-text-right mx-1"
              @click="addNewItem(partItem)">
              <div class="butterfly-item-values is-flex is-justify-content-center"
                :class="{ ' has-text-weight-bold is-selected': getExistingItem(partItem, itemCategoryTypes.PART) }">
                +
              </div>
            </div>

          </div>
        </div>

      </div>

      <!-- <bulma-table class="table is-striped is-narrow is-fullwidth"
        :columns="columns"
        :page-index="filter.pageIndex"
        :page-size="filter.pageSize"
        :total-rows="totalRows"
        :is-loading="isDataLoading"
        :sort-column="filter.sortColumn"
        :sort-order="filter.sortOrder"
        @pageChanged="onPageChange"
        @sort="sort">
        <tr v-for="(item, index) in availableItems"
          :key="index"
          @dblclick="addNewItem(item, index)">
          <td>{{ item.itemNo }}</td>
          <td>{{ item.description }}</td>
          <td class="has-text-centered">{{ item.side }}</td>
          <td class="has-text-centered">{{ item.quantity }}</td>
          <td class="has-text-right mr-2"
            @click="addNewItem(item, index)">
            <div class="butterfly-item-values is-flex is-justify-content-center px-2 mr-2"
              :class="{ ' has-text-weight-bold is-selected': getExistingItem(item, itemCategoryTypes.PART) }">

              +
            </div>
          </td>

        </tr>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table> -->
    </div>
    <div>
      <butterfly-modal v-if="isButterflyModalActive"
        :active.sync="isButterflyModalActive"
        v-model="selectedButterflyCodes"
        @filterButterfly="butterflySearch"
        @reset="reset"
        @done="filterButterly"
        @cancel="cancel">
        <div slot="text-content">
          <butterfly v-if="isButterflyModalActive"
            v-model="selectedButterflyCodes" />
        </div>
      </butterfly-modal>
    </div>
  </div>
</template>

<script>
import QuoteService from '../QuoteService'
import { AvailablePartsColumns } from '../columns'
import { ItemCategoryTypes, PartTypes, QuoteItemRevTypes } from '@/enums'
// import BulmaTable from '@/components/BulmaTable'
import _debounce from 'lodash.debounce'
import { Butterfly, ButterflyModal } from '@/components/Butterfly'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
// import Guid from '@/components/Guid'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { QuoteItemModel } from '@/classes/viewmodels'
import Spinner from '../../../components/Spinners/Spinner.vue'

export default {
  name: 'AvailableParts',
  components: {
    // BulmaTable,
    Butterfly,
    ButterflyModal,
    Spinner
  },
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    vehicleId: {
      type: String,
      default: ''
    },
    quoteNo: {
      type: Number,
      default: 0
    },
    subQuoteNo: {
      type: Number,
      default: 0
    },
    subQuoteNos: {
      type: Array,
      default: function () {
        return []
      }
    },
    isAudanet: {
      type: Boolean,
      default: false
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 8
    },
    showScrollToAdd: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerValue: null,
      selectedRow: null,
      isDataLoading: false,
      totalRows: 0,
      filter: {
        vehicleId: this.vehicleId,
        query: '',
        butterflyCode: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      availableItems: null,
      selectedButterflyCodes: [],

      expandedButterflyCodes: [],
      sortedData: [],

      isButterflyModalActive: false,
      partsMain: null,
      partsSupp: null
    }
  },
  computed: {
    columns() {
      return AvailablePartsColumns
    },
    newItems() {
      return this.value.filter((i) => i.isNew && !i.deleted)
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    isExpandAll() {
      return this.expandedButterflyCodes.length === this.sortedData.length
    }
  },
  watch: {
    availableItems: {
      handler: function (value) {
        if (!!value.length) {
          this.getSortedData()
        }
      },
      deep: true
    }
  },
  created() {
    // this.innerValue = this.value
    this.getAvailableParts()
    // this.getQuoteItems()
  },
  mounted() {},
  methods: {
    handleExpandAll() {
      if (this.isExpandAll) {
        this.expandedButterflyCodes = []
      } else {
        this.expandedButterflyCodes = this.sortedData?.map((item) => item.butterflyCode)
      }
    },

    handleAddAllItems(items, event) {
      event.stopPropagation()
      items.forEach((item) => {
        this.addNewItem(item, false)
      })
    },
    isButterflyCodeSelected(code) {
      return this.expandedButterflyCodes.includes(code)
    },
    handleToggleButterflyCode(code) {
      if (this.isButterflyCodeSelected(code)) {
        this.expandedButterflyCodes = this.expandedButterflyCodes.filter((c) => c !== code)
      } else {
        this.expandedButterflyCodes.push(code)
      }
    },

    getSortedData() {
      const data = this.availableItems

      const groups = {}
      data.forEach((item) => {
        // Use 'other' for null, undefined, or empty butterflyCode.
        const key = item.butterflyCode ? item.butterflyCode : 'other'
        if (!groups[key]) {
          groups[key] = {
            butterflyDesc:
              item.butterflyCode && item.butterflyDescription ? item.butterflyDescription : !!item.butterflyCode ? item.butterflyCode + ' (code) ' : 'Other',
            butterflyCode: key,
            butterflysortorder: item.butterflyCode ? item.butterflySortOrder : 9999,
            items: []
          }
        }
        groups[key].items.push(item)
      })

      const sorted = Object.values(groups).sort((a, b) => {
        const orderA = a.butterflysortorder === null ? Infinity : a.butterflysortorder
        const orderB = b.butterflysortorder === null ? Infinity : b.butterflysortorder
        return orderA - orderB
      })

      console.log(sorted)

      this.sortedData = sorted
    },

    async getAvailableParts() {
      this.isDataLoading = true
      this.availableItems = await QuoteService.getAvailableParts(this.filter)
      this.totalRows = this.availableItems.length > 0 ? this.availableItems[0].totalRows : 0
      this.isDataLoading = false
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getAvailableParts()
    },
    sort(name, order) {},
    reset() {
      this.selectedButterflyCodes = []
      this.filter.query = ''
      this.filter.butterflyCode = ''
      this.filter.pageIndex = 1
      this.getAvailableParts()
    },
    search: _debounce(function () {
      this.filter.pageIndex = 1
      this.getAvailableParts()
    }, 500),
    butterflySearch() {
      this.isButterflyModalActive = true
    },
    filterButterly() {
      this.isButterflyModalActive = false
      this.filter.butterflyCode = this.selectedButterflyCodes.join()
      this.getAvailableParts()
    },
    cancel(value) {
      this.selectedButterflyCodes = value
      this.isButterflyModalActive = false
    },
    getExistingItem(item) {
      return this.value.find((i) => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && !i.deleteOtherItem)
    },
    addNewItem(item, deleteItems = true) {
      const existingItem = this.getExistingItem(item)
      if (!existingItem) {
        let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, ItemCategoryTypes.PART)
        newItem.lineNumber = !this.isAudanet ? this.nextLineNumber : 0
        newItem.itemQuantity = item.quantity
        newItem.value = roundAwayFromZero(item.quantity * item.unitPrice)
        newItem.mark = PartTypes.NEW.value
        newItem.markupPercent = 0
        newItem.markupValue = roundAwayFromZero(item.quantity * item.unitPrice)
        newItem.buyPrice = item.tradePrice
        newItem.partNo = item.partNo
        newItem.side = item.side
        newItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
        if (this.isAudanet) {
          newItem.rev = QuoteItemRevTypes.NonAudaNetPart
        }
        this.$emit('item-added', newItem)
      } else {
        if (deleteItems) {
          this.$eventHub.$emit('deletePart', existingItem)
        } else {
          this.$notification.openNotificationWithType('warning', 'Parts', 'Part is already in selected list. Item not added.')
        }
        // this.$notification.openNotificationWithType('warning', 'Parts', 'Part is already in selected list. Item not added.')
      }
    },
    async getQuoteItems() {
      if (this.subQuoteNo !== 0) {
        this.partsMain = await QuoteService.getQuoteItems(this.quoteNo, 0, [ItemCategoryTypes.PART])
      } else {
        const vm = this
        this.partsSupp = {}
        // this.subQuoteNos.forEach(async function(q) {
        //   if (q.key !== 0) {
        //     const items = await QuoteService.getQuoteItems(vm.quoteNo, q.key, ItemCategoryTypes.PART)
        //     const prop = `parts${q.key}`
        //     vm.partsSupp[prop] = items
        //   }
        // })
        const promises = this.subQuoteNos.map(async function (q, index) {
          if (q.key !== 0) {
            const items = await QuoteService.getQuoteItems(vm.quoteNo, q.key, [ItemCategoryTypes.PART])
            const prop = `parts${q.key}`
            vm.partsSupp[prop] = items
          }
        })
        await Promise.all(promises)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/style/themes/default/variables';

#svgButterfly {
  fill: $primary;
}
// .button:hover svg path {
//   fill: white;
// }
.button:hover #svgButterfly {
  fill: white;
}

.butterfly-list-labour-item-category {
  border-radius: 5px;
  background: lighten($grey, 40%);

  &:hover {
    background-color: lighten($grey, 30%);
  }
}

.butterfly-list-labour-item {
  border-radius: 5px;
  border: 1px solid lighten($grey, 30%);
}

.butterfly-list-labour-item,
.butterfly-list-labour-item-category {
  display: grid;
  grid-template-columns: 80% 15% 5%;
}
</style>
